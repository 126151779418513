 @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Lato:wght@300;400;700&display=swap');

html { overflow-x: hidden; }
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Padding */
.p0 { padding: 0px !important; }
.p1 { padding: 1px !important; }
.p2 { padding: 2px !important; }
.p5 { padding: 5px !important; }
.p6 { padding: 6px !important; }
.p-1 { padding: 10px !important; }
.p-2 { padding: 20px !important; }
.p-3 { padding: 30px !important; }
.p-4 { padding: 40px !important; }
.pb2 { padding-bottom: 2px !important; }
.pb5 { padding-bottom: 5px !important; }
.pb7 { padding-bottom: 7px !important; }
.pb-1 { padding-bottom: 10px !important; }
.pb-15 { padding-bottom: 15px !important; }
.pb-2 { padding-bottom: 20px !important; }
.pb-3 { padding-bottom: 30px !important; }
.pl5 { padding-left: 5px !important; }
.pl7 { padding-left: 7px !important; }
.pl-0 { padding-left: 0px !important; }
.pl-1 { padding-left: 10px !important; }
.pl-15 { padding-left: 15px !important; }
.pl-2 { padding-left: 20px !important; }
.pl-35 { padding-left: 35px !important; }
.pl-4 { padding-left: 40px !important; }
.pl-5 { padding-left: 50px !important; }
.pl-6 { padding-left: 60px !important; }
.pl-8 { padding-left: 80px !important; }
.pl-3 { padding-left: 30px !important; }
.pl-10 { padding-left: 100px !important; }
.pr5 { padding-right: 5px !important; }
.pr-1 { padding-right: 10px !important; }
.pr-15 { padding-right: 15px !important; }
.pr-2 { padding-right: 20px !important; }
.pr-3 { padding-right: 30px !important; }
.pr-4 { padding-right: 40px !important; }
.pr-5 { padding-right: 50px !important; }
.pt1 { padding-top: 1px !important; }
.pt5 { padding-top: 5px !important; }
.pt7 { padding-top: 7px !important; }
.pt-0 { padding-top: 0px !important; }
.pt-1 { padding-top: 10px !important; }
.pt-2 { padding-top: 20px !important; }
.pt-24 { padding-top: 24px !important; }
.pt-3 { padding-top: 30px !important; }
.pt-4 { padding-top: 40px !important; }
.pt-5 { padding-top: 50px !important; }
.pt-6 { padding-top: 60px !important; }
.pt-7 { padding-top: 70px !important; }
/* Margin */
.mb2 { margin-bottom: 2px !important; }
.mb5 { margin-bottom: 5px !important; }
.mb6 { margin-bottom: 8px !important; }
.mb7 { margin-bottom: 8px !important; }
.mb8 { margin-bottom: 8px !important; }
.mb-1 { margin-bottom: 10px !important; }
.mb15 { margin-bottom: 15px !important; }
.mb-2 { margin-bottom: 20px !important; }
.mb-24 { margin-bottom: 24px !important; }
.mb-3 { margin-bottom: 30px !important; }
.mb-4 { margin-bottom: 40px !important; }
.mb-5 { margin-bottom: 50px !important; }
.mt0 { margin-top: 0px !important; }
.mt5 { margin-top: 5px !important; }
.mt8 { margin-top: 8px !important; }
.mt-1 { margin-top: 10px !important; }
.mt-15 { margin-top: 15px !important; }
.mt-2 { margin-top: 20px !important; }
.mt-3 { margin-top: 30px !important; }
.mt-4 { margin-top: 40px !important; }
.mt-5 { margin-top: 50px !important; }
.mt-7 { margin-top: 70px !important; }
.mr5 { margin-right: 5px !important; }
.mr-1 { margin-right: 10px !important; }
.mr-15 { margin-right: 15px !important; }
.mr-2 { margin-right: 20px !important; }
.mr-3 { margin-right: 30px !important; }
.mr-4 { margin-right: 40px !important; }
.mr-5 { margin-right: 50px !important; }
.ml5 { margin-left: 5px !important; }
.ml-1 { margin-left: 10px !important; }
.ml-15 { margin-left: 15px !important; }
.ml-2 { margin-left: 20px !important; }
.ml-3 { margin-left: 30px !important; }
.ml-4 { margin-left: 40px !important; }
/* fonts */
.fs-16 { font-size: 16px !important; }
.fs-17 { font-size: 17px !important; }
.fs-18 { font-size: 18px !important; }
.fs-19 { font-size: 19px !important; }
.fs-20 { font-size: 20px !important; }
.fw-400 { font-weight: 400 !important; }
.fw-500 { font-weight: 500 !important; }
.fw-600 { font-weight: 600 !important; }
.fw-700 { font-weight: 700 !important; }
/* Width */
.w-100 { width: 100% !important; }
.h-100 { height: 100% !important; }
/* flex */
.relative { position: relative !important; }
.inlineFlex { display: inline-flex !important; }
.dflex { display: flex !important; }
.flexOne { flex: 1 !important; }
.alignEnd { align-items: end !important; }
.alignCenter { align-items: center !important; }
.alignBaseline { align-items: baseline !important; }
.alignFlexStart { align-items: flex-start !important; }
.spaceBetween { justify-content: space-between !important; }
.justifyCenter  { justify-content: center !important; }
.flexEnd { justify-content: flex-end !important; }
/* Text */
.italic { font-style: italic !important; }
.noWrap { white-space: nowrap !important; }
.breakWord { word-break: break-word; }
.capitalize { text-transform:capitalize !important; }
.pointer { cursor: pointer !important; }
.eventNone { pointer-events: none !important; }
.text-align-center { text-align: 'center'; }
.bold { font-weight: bold !important; }
/* Scrollbar */
::-webkit-scrollbar { width: 4px; height: 6px; }
::-webkit-scrollbar-track { background: #f1f1f1; }
::-webkit-scrollbar-thumb { background: #B0BAC9; border-radius: 6px; }
::-webkit-scrollbar-thumb:hover { background: #555; }

.oneLineEllip {
  display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.twoLineEllip {
  display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/* Animations */
.HeartFilledIcon {
  animation: heartFillAnim 0.2s linear;
}

@keyframes heartFillAnim {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

.loadingActive .searchIcon {
  animation: searchAnim 1.5s linear infinite;
  fill: #64AAEF;
}
@keyframes searchAnim {
  0%   {transform: translateX(5px);}
  25%  {transform: translateY(5px);}
  50%  {transform: translateX(-5px);}
  75% {transform: translateY(-5px);}
  100% {transform: translateX(5px);}
}
.loadingActive .backIcons {
  animation: backiconsAnim 1.5s linear infinite;
  fill: #64AAEF;
}
@keyframes backiconsAnim {
  0%   {opacity: 0;}
  50% {opacity: 1}
  75% {opacity: 0.75}
  100% {opacity: 0}
}

li.MuiBox-root .MuiTypography-root {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

svg text {
  text-transform: capitalize !important;
}

@keyframes attachmentAnim {
  0%   {opacity: 1;}
  10%   {opacity: 0.7;}
  20%  {opacity: 0.5;}
  40%  {opacity: 0.3;}
  50%  {opacity: 0;}
  60%  {opacity: 0.2;}
  80%  {opacity: 0.5;}
  100% {opacity: 0.7;}
}